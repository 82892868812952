.paginationWrapper {
    padding: 0;
    display: flex;
    justify-content: center;
}
.separator {
    width: 1rem;
    margin: 0 0.25rem;
}
.pageItem {
    background: transparent;
    border: none;
    height: 2rem;
    width: 2rem;
    margin: 0 0.25rem;
    border-radius: 50%;
    font-weight: 600;
    color: #141 44;
}
.pageItem:hover {
    text-decoration: underline;
}
.pageItem:focus {
    outline: 0;
}
.active {
    background-color: #141 44;
    color: white;
}
.sides {
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.sides:hover {
    text-decoration: none;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px;
}

button.pageItem.active {
    background: green;
}
button.pageItem.sides.deactive{
    background: #d5d5d5!important;
    color: gray;
}
.arrawSearch {
  display: flex;
  justify-content: space-between;
}
.arrawIcon {
  height: 18px;
  margin-left: 21%;
  margin-top: 50%;
}

ul.search_filter_ul {
  padding-left: 0px;
}
.search_filter_item {
  display: flex;
}

.search_location_img {
  height: 2rem;
  align-self: center;
}

ul.search_filter_ul .first_item {
  font-weight: 500;
}

.search_div {
  position: fixed;
  width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  top: 0px;
  left: 0px;
  background: #f4f4f5;
}
.search_bar-container {
  background: #fff;
  width: 100%;
}
input.search_bar {
  padding: 11px 10px;
  border: 0px;
  outline: none;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  background: no-repeat;
  margin-left: 30px;
  width: calc(100% - 30px);
}
li.result_search {
  list-style: none;
  display: flex;
  padding-bottom: 0.6rem;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
}

.search_location_img {
  margin-right: 1rem;
}
li.autoshow {
  margin-top: 1rem;
}
li.result_search {
  border-bottom: 0.5px dashed #ccc;
  margin-bottom: 7px;
}
li.result_search .icon_container {
  min-width: 30px;
  text-align: center;
}
li.result_search img {
  margin-right: 1rem;
  height: 100%;
  margin: auto;
}
ul.search_ul {
  padding-left: 0px;
  padding-right: 1rem;
  background: #fff;
  padding: 7px;
}
div.result_search {
  display: flex;
  font-weight: 700;
  margin: 7px auto;
  background: #fff;
  padding: 7px;
}
.search_name {
  width: 100%;
  color: #2f2e2e;
  text-transform: capitalize;
}
.search_name .title {
  font-size: 13px !important;
}
.search_name small {
  overflow: hidden;
  color: #444;
  text-overflow: ellipsis;
  width: 100%;
}

.marker-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 89;
  position: relative;
}
.marker_div {
  width: 28px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 40px;
}
.marker_pic {
  position: absolute;
  top: -1.1rem;
  left: -0.05rem;
  width: 100%;
  height: 100%;
}
.pulse-box {
  float: left;
  width: 50%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* pulse in CSS */
.pulse-css {
  height: 30px;
  border-radius: 0.5px;
  position: absolute;
  left: 60%;
  top: 60%;
  transform: translate(-50%, -50%);
}

.pulse-css:before,
.pulse-css:after {
  content: "";
  width: 1px;
  height: 1px;
  border-radius: 0.5px;
  background-color: green;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(10);
  transform-origin: center center;
  animation: pulse-me 1s linear infinite;
}
.pulse-css:after {
  animation-delay: 0.2s;
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.09;
  }
  100% {
    transform: scale(75);
    opacity: 0;
  }
}
.count p {
  cursor: pointer;
}
span.recent_search_mobile {
  color: gray;
  font-size: 0.7rem;
  margin-bottom: 1rem !important;
  display: block;
  font-weight: 700;
}
@media (max-width: 768px) {
  li.result_search:first-child {
    margin-top: 1rem !important;
  }
}

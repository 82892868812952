.local_pickup_div {
  background: #f6faf5;
  background-image: url(https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/BgCal_mb7FcvF4t.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663272381299);
  background-position: right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 46% !important;
  height: 100vh;
  background-size: 44rem, 95rem;
}

.profileName_pic p {
  margin-bottom: 0px;
  margin-right: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  color: #808080;
}

.profileName_pic {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: auto;
}

.person_circle_icon {
  font-size: 2rem;
  color: #257430;
}
.pickUp_view_local {
  margin-top: 1rem;
  height: 78vh;
}
.show_pickup_title {
  font-size: .9rem;
}
p.pickups_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0rem;
  padding: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  padding-bottom: 1rem;
}
p.pickups_title span {
  cursor: pointer;
  text-align: center;
  width: 50%;
}
p.pickups_title .active {
  color: #3ab34a;
  margin-top: 0px;
  border-bottom: 2px solid green;
  padding-bottom: 0.5rem;
}
ul.pickups_list_ul {
  list-style: none;
  padding: 0px 10px 0px 0px;
  text-align: center;
  margin-bottom: 10px;
  overflow: auto;
  height: 58vh;
  background: #f6faf5;
}
.pickups_list_ul li {
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  text-align: start;
  margin-bottom: 1.5rem;
}
.justify-between {
  justify-content: space-between;
}
.show_pickup_item {
  font-size: .9rem;
}
.fetch_limit {
  cursor: pointer;
  text-align: center;
  width: 2rem;
  padding: 3px 5px;
  border: 1px solid gray;
  border-radius: 3px;
  margin-left: .5rem;
}
.pickups_list_ul li .main_view div {
  width: 37%;
}
.pickups_list_ul li .main_view div:nth-child(1),
.pickups_list_ul li .main_view div:nth-child(4) {
  width: 25%;
}
.pickups_list_ul li .main_view {
  position: relative;
  display: flex;
  gap: 1rem 0rem;
  align-items: center;
  flex-wrap: wrap;
  align-items: self-start !important;
}
.status_pickup {
  position: absolute;
  bottom: 0rem;
  right: -.5rem;
}
.pickups_list_ul li .main_view,
.pickups_list_ul li .details_view {
  align-items: center;
}
.pickups_list_ul li .details_view {
  transition: height 0.3s;
  height: 0px;
  overflow: hidden;
}

.pickups_list_ul li .details_view.view {
  margin-top: .3rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: self-start;
}
.details_d_flex {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}
.details_view_ques {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #5b5b5b;
  margin-right: .1rem;
  width: 5.7rem;
}
.details_view_ans {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #5b5b5b;
  width: 100%;
}
.details_view_ans.address {
  display: flex;
  font-weight: 500;
}
.pickups_list_ul li p {
  margin: 0;
  text-align: start;
}

.schedule_pickup_hr {
  background: #969696;
  margin-top: 1.2rem;
  height: 2px;
}

h5.pickup_details_day {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin: 0px;
  text-align: center;
  color: #5b5b5b;
}
h6.pickup_details_data {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin: 0px;
  color: #5b5b5b;
  text-align: center;
}
.pickup_details_name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;

  color: #5b5b5b;
}
.pickup_details_weight,
.pickup_details_price {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #3ab34a;
}

ion-icon.down_arrow {
  position: absolute;
  top: 0;
  right: 0rem;
  font-size: 1.7rem;
  margin-left: auto;
  cursor: pointer;
  align-self: self-start;
  color: #5b5b5b;
}
button.local_next_button.schedule_pickup_button {
  width: 340px;
  height: 63px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 0px;
}
.local_pending_status {
  background: none;
  box-shadow: none;
  justify-content: space-around;
}
.local_pending_status ion-chip {
  width: 50%;
  text-align: center;
  font-weight: 500;
}
ion-chip {
  font-weight: 500;
}
.local_pending_status ion-chip span {
  display: block;
  text-align: center;
  width: 100%;
  font-weight: 500;
  text-transform: uppercase;
}
button.accordion_btn {
  display: block;
  width: 100%;
  background: none;
  border: none;
}
button.accordion_btn:hover {
  background: none;
}
button.accordion_btn:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
button.accordion_btn:active {
  background: none !important;
  border: none !important;
  outline: none !important;
}
button.accordion-button,
button.accordion-button:active,
button.accordion-button:focus {
  background: none;
  border: none;
  box-shadow: none;
  width: 100%;
}
.status_pickup {
  padding: 0.3rem .5rem;
  border-radius: 1rem;
  font-size: .7rem;
  width: fit-content;
  word-break: keep-all;
}
.status_pickup.schedule {
  background-color: #21b865 !important;
  color: white !important;
}
.status_pickup.cancelled {
  background-color: #f85c73 !important;
  color: white !important;
}
.status_pickup.completed {
  background-color: #a21d46 !important;
  color: white !important;
}

.pickup_details_title {
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: capitalize !important;
}
.pickup_details_result {
  font-size: .8rem;
  font-weight: 600;
}
.gap-2 {
  gap: .2rem;
}
.flex-col {
  flex-direction: column;
}
.justify-start {
  justify-content: flex-start;
}
.pickup_li {
  padding: .2rem;
  transition: all .3s;
}

.details_view.active {
  max-height: 100% !important;
}

.details_view {
  max-height: 0 !important;
  height: 100%;
}
.local_container,
.local_row {
  padding-top: 0px !important;
  height: 92vh;
  overflow: auto;
  padding-bottom: 3rem;
}
.cancel_call_p {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  margin-top: 9px;
  color: black;
}
.pagination_show_items {
  flex-wrap: wrap;
  gap: 0.6rem;
}


#hide_scrollBar::-webkit-scrollbar {
  width: 0em!important;
}
 
#hide_scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)!important;
}
 
#hide_scrollBar::-webkit-scrollbar-thumb {
  background-color: darkgrey!important;
  outline: 1px solid slategrey!important;
}

@media (max-width: 768px) {
  .local_pickup_div {
    background-image: none;
  }
}
@media (max-width: 514px) {
  .status_pickup {
    bottom: -1.7rem;
  }
}

.google_map_location_main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  color: white;
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  background-color: white;
}
.google_map_location_main.show {
  opacity: 1;
  visibility: visible;
}
.google_map {
  background-color: white;
  width: 100%;
  max-height: 50vh;
  height: 100%;

  color: black;
}
.google_map_header {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  z-index: 102;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.google_map_header div {
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: 50%;
  padding: .3rem;
}
.google_map_icon {
  font-size: 1.5rem;
}

.other_thing {
  padding: 1rem;
  max-height: 50vh;
  height: 100%;
  position: relative;
  color: black;
}

.marker-google_location {
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;
}

.save_proceed_btn {
  height: 3rem !important;
  width: 90%;
  margin: 1rem 1.3rem;
}

.map_info_main_div {
  background-color: rgb(240, 240, 240);
  border-radius: .5rem;
  padding: .6rem 0rem;
}
.map_info_label {
  margin: 0 1rem;
  margin-bottom: 5px;
  font-weight: 600;
  border-bottom: 1px dashed gray;
  font-size: .9rem;
}
.map_info {
  margin: 0 1rem;
  margin-bottom: 0px;
  font-weight: 500;
  margin-left: 1rem;
  font-size: .8rem;
}
.map_info_div {
  padding: .4rem 0rem;
  border-radius: .5rem;
}

.locate_icon {
  font-size: 2rem;
  color: #6e6e6e;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}

html[data-theme="dark"] {
  --text-color-normal: hsl(210, 10%, 62%);
  --text-color-light: hsl(210, 15%, 35%);
  --text-color-richer: hsl(210, 50%, 72%);
  --text-color-highlight: hsl(25, 70%, 45%);
}

@media (max-width: 768px) {
  .web-view {
    display: none;
  }

  .ion-page {
    background-color: #e8e8e8;
  }
}

@media (min-width: 992px) {
  .mobile-view {
    display: none;
  }

  ion-tab-bar.IonTabBar.md.hydrated {
    display: none;
  }
}

input:autofill {
  color: black !important;
}

input {
  background: white;
}

.tabBar_image {
  width: 27px;
  height: 27px;
  margin: 5px auto 0px;
}

ion-label.sc-ion-label-ios-h.sc-ion-label-ios-s.ios.hydrated {
  font-size: 12px;
  color: gray;
}

.hydrated {
  visibility: inherit;
}

.mobile-view {
  height: 100%;
}

.flex_column {
  display: flex;
  flex-direction: column;
  width: 178%;
}

.web-view {
  width: 1440px;
  overflow-y: auto;
  margin: 0 auto;
  height: 100%;
}

.react-calendar__navigation button {
  min-width: 30px !important;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  border-radius: 50%;
  /* max-width: 13.6%!important; */
}

button.react-calendar__tile {
  max-width: 13.6% !important;
  margin-left: 0.5%;
}

ion-icon {
  cursor: pointer;
}

.d-hotline.h-btn:focus {
  outline: none !important;
  border: none !important;
}

ion-tab-bar.IonTabBar {
  border-top: 1px solid #3ab34a !important;
}

.react-datepicker__day--in-range {
  background-color: gray;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #21a52f !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: 1.7rem;
  margin: 1% !important;
  width: 12% !important;
  padding: 2% 0px;
}

@media (max-width: 768px) {
  .f-sm-column-reverse {
    flex-direction: column-reverse;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* GENERAL CSS  */
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.p-2 {
  padding: 2rem;
}

.p-1 {
  padding: 1rem;
}

@media only screen and (min-width: 994px) {
  .mobile-only {
    display: none;
  }
}

.hidden {
  display: none;
}

@media only screen and (min-width: 994px) {
  .mobile-only {
    display: none;
  }
}

@media only screen and (max-width: 994px) {
  .desktop-only {
    display: none;
  }
}

/* .instant-schedule-btn {
  position: static;
  right: 80px;
  top: 14px;
} */
.mobile-menu-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.sharktank-banner {
  width: 100%;
}

.site-notice {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #fff3cd;
  color: #856404;
  border-radius: 7px;
  font-size: 13px;
}
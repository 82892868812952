@media (max-width: 768px) {
  .web_view_tab {
    display: none;
  }
}
.web_view {
  height: 110vh;
  overflow: scroll;
}
.web_view_main_content {
  display: flex;
  justify-content: space-between;
}
.web_view_tab {
  width: 20%;
  padding: 20px 10px;
  background-color: white;
}
.btn-schedule-pickup {
  margin-top: 1rem;
}
.web_view_impact_content {
  width: 50%;
  background: #e1ecf1;
  padding: 20px 10px;
}
.web_view_upcoming_picksup {
  width: 30%;
  background-color: #e1ecf1;
  padding: 20px 10px;
}
.web_view_top {
  padding: 20px 35px;
  font-weight: 800;
}
.web_view_tab ul {
  list-style: none;
  padding-left: 0rem;
}
.web_view_tab ul li {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.web_view_tab ul {
  padding-top: 1rem;
}

.web_view_tab ul li h4 {
  padding-left: 1rem;
  color: black;
  font-size: 18px;
}
.active_page {
  font-weight: bolder;
}
.middle_content_image {
  background: white;
  margin-top: 1rem;
  padding-top: 5rem;
  border-radius: 10px;
  position: relative;
}
.recycled_kg {
  position: absolute;
  text-align: center;
  top: 5rem;
  left: 6rem;
}
.recycled_kg p {
  margin: 0px;
  font-size: 2.5rem;
}
.earned {
  position: absolute;
  top: 1rem;
  right: 11rem;
  text-align: center;
}
.earned p {
  margin: 0px;
  font-size: 2.5rem;
}
.header_title {
  font-size: 31px;
  font-weight: bold;
}
.react-calendar.calender {
  margin-top: 1rem;
  width: 100% !important;
}
.web_view_box {
  margin-top: 0.7rem;
}
.web_view_box .location p {
  font-size: 13px;
}
.web_view_box .date h3 {
  font-size: 18px;
}
.web_view_box .date b {
  font-size: 29px;
}
.web_view_box .location span {
  font-size: 11.7px;
}
.middle_content_image.pt {
  padding: 25px;
}
.web_view_impact_content.web_view_top {
  padding-top: 0px;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 1.5rem;
}
button.react-calendar__tile.react-calendar__month-view__days__day {
  padding: 4% 2%;
  border-radius: 50%;
}
.react-calendar__month-view__weekdays {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation button {
  min-width: 100px;
  background: none;
  font-size: 53px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}
.overlay::after {
  content: "";
  background: #ffe07d70;
  height: 82%;
  width: 92%;
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 9%;
}

.web_view_main_content {
  height: 94vh;
}
button.react-calendar__navigation__label {
  margin-top: -0.4rem;
}

.content_div {
  height: 92vh;
  overflow-y: auto;
  margin-top: 1rem;
  padding: 0px 20px 0px 0px;
}
button.react-calendar__tile {
  max-width: 24.6% !important;
}

a:hover {
  text-decoration: none;
  color: black;
}
/*
 *  STYLE 2
 */
@media (min-width: 768px) {
  .style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #ffffff;
  }

  .style-2::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
  }
  .style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #a5a5a500;
  }
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:focus {
  background: #3ab34a !important;
}
h3.overlay_text {
  text-align: center;
  position: absolute;
  color: white;
  z-index: 100;
  left: 7rem;
  font-size: 29px;
  width: 61%;
  top: 3rem;
}
.side_bar {
  background: white;
  position: fixed;
  top: 0rem;
  right: -30rem;
  width: 25%;
  transition: all 0.3s;
  padding-right: 1rem;
  height: 100% !important;
  z-index: 21474836001265;
  box-shadow: -6px 5px 13px #00000014;
  opacity: 0;
}
.p-3 {
  padding: 1rem;
}
.side_bar.open {
  right: 0rem;
  transition: all 0.3s;
  height: -webkit-fill-available;
  opacity: 1;
}
.cart_ul {
  height: 67%;
  overflow-y: auto;
  padding-right: 0.8rem;
  padding-left: 1.5rem;
  box-shadow: 0px 6px 5px #8e8b8b52;
}
.cart_ul li {
  padding: 1rem 0rem;
  box-shadow: 0px 5px 8px #0000000d;
}
.cart_ul li p {
  margin: 0px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.edit_btn {
  border-radius: 7px;
  padding: 5px;
  background: #5d5d5d;
  border-radius: 8px;
  color: white;
  width: 48px;
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
#continue_scheduling {
  position: absolute;
  bottom: 1rem;
  left: 2.2rem;
}
#continue_scheduling p {
  text-align: right;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.header_title.h2 {
  margin-top: 0px;
}

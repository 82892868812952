.page_three_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 10px;
}
.page_three_container .text {
  margin-left: 0px;
}
.text-threePage {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}
.threeBar,
.calender {
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 3px;
  margin: 0px 2px;
}
.threeBar {
  border-color: #3ab34a;
}
.d-flex.page_three_box {
  background: #ffffff;
  padding: 10px 10px;
  margin-bottom: 5px;
  padding-top: 1rem;
  cursor: pointer;
}

.bg_three_page {
  position: fixed;
  bottom: 0;
  z-index: -1;
}

.upcoming-pickup {
  height: 80%;
  overflow-y: auto;
  padding-bottom: 4.5rem;
}

.pending_status {
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 10px rgb(219 219 219 / 30%);
  padding-bottom: 5px;
}
.activePending {
  background-color: #5a97f1 !important;
  color: white !important;
}
.pending IonLabel {
  color: #6ea4f5 !important;
}
.activeSchedule {
  background-color: #21b865 !important;
  color: white !important;
}
.completed_status {
  color: black;
  background-color: #7503254e;
}
.activeCompleted {
  background-color: #a21d46 !important;
  color: white;
}
.schedule IonLabel {
  color: #6ff3aa !important;
}
.activeCanceled {
  background-color: #f85c73 !important;
  color: white !important;
}
.cancel IonLabel {
  color: #f77387 !important;
}
.item_info {
  display: flex;
}
.show_item .alert-head {
  padding: 10px 0 0 0 !important;
}
.alert_date h3 {
  margin: 0px;
}
.alert_date {
  background: rgba(3, 196, 3, 0.788);
  color: white;
  padding: 0.6rem;
  border-radius: 11px;
  height: 100%;
}
.time_address h5 {
  margin: 0px 0 0.5rem 6px;
  background-color: rgba(204, 204, 204, 0.514);
  padding: 5px;
  border-radius: 10px;
}
.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-ios {
  min-width: 90% !important;
  min-height: 50% !important;
  margin-top: 0px !important;
  top: initial !important;
}

.modal-wrapper.ion-overlay-wrapper.sc-ion-modal-ios {
  border-radius: 10px 10px 0px 0px;
}
.modal_header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  color: white;
}
.closeIcon {
  font-size: 2rem;
}
.modal_cover_div {
  background: #00ab4d;
  height: 100%;
  overflow: auto;
}
.modal_content {
  overflow: auto;
  height: 91.5%;
  display: flex;
  flex-direction: column;
}
.pickup_agent_profile {
  font-size: 8rem;
}
.pickup_detail {
  margin-top: -0.7rem;
}
.pickup_agent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  color: white;
  font-weight: bold;
}
p.pickup_agent_name,
p.pickup_agent_mobile {
  margin-bottom: 0;
  font-weight: 500;
  color: white;
  font-size: 1.3rem;
  line-height: 1.3;
}
.pickup_detail_div {
  background: white;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 15px #878787;
  height: 100%;
  border-radius: 45px 45px 0px 0px;
}
p.details_text.m-0.text-muted {
  font-weight: 500;
  font-size: 1.1rem;
  color: #989898 !important;
  letter-spacing: 1px;
}
.first_details_text {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.1rem;
}

.second_details_text {
  display: flex;
  margin-bottom: .5rem;
  font-weight: 500;
  align-items: center;
}
.second_details_text:first-child {
  justify-content: space-between;
}
.second_details_text:last-child {
  margin-bottom: 0px;
}
.arrow_icon_div {
  display: flex;
  position: relative;
}
.arrow_icon_div .pickup_details_icon {
  font-size: 1rem;
}
.arrow_icon_div .pickup_details_icon:first-child {
  position: absolute;
  left: -9px;
}
.second_details_div {
  padding: 1rem 1.4rem;
  box-shadow: 6px 5px 28px #969696;
  margin-top: 1rem;
  border-radius: 20px;
}
.pickup_details_icon {
  font-size: 2rem;
  font-weight: 600;
}
p.text-muted.m-0.pl-3 {
  width: 100%;
  font-size: 15px;
}
p.m-0.text-muted {
  font-size: 0.9rem;
}
p.first_details_text_invoice {
  font-weight: bold;
}
@media (min-width: 768px) {
  .upcoming-pickup {
    overflow: hidden !important;
  }
  .pending_status {
    background-color: white;
    border-radius: 5px;
  }

}
@media (max-width: 768px) {
  .activeSchedule,
  .completed_status,
  .activeCompleted {
    font-size: .8rem;
  }
}

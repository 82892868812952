.address_text{
    display: flex;
    align-items: center;
    font-size: .8rem;
    color: red;
    gap:.5rem;
}
.add_address_manually{
    padding: .5rem .8rem;
    border-radius: 5px;
    background: rgb(233, 233, 233);
    cursor: pointer;
}
.main_address_div{
    border-top: 1px solid #d2d2d2;
}
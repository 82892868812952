.d-flex-pickupdetails {
  align-items: center;
}
.btn-primary {
  background-color: transparent !important;
  border: none;
}
.btn-primary::after {
  display: none !important;
}
.btn-primary:focus {
  outline: none;
  box-shadow: none !important;
}
.top-div {
  padding-top: 0px;
  padding-left: 13rem;
  padding-right: 0px;
}
ion-header#ion_header {
  background: white !important;
}
button.dropdown-toggle.btn.btn-primary {
  width: 45px;
}
.gray-text {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #6d6d6d;
  text-align: center;
}
.address_list {
  box-shadow: 0px 1px 5px #cacaca;
  border-radius: 5px;
  padding: 0.1rem 0rem;
  margin-bottom: 0.7rem;
}
.icon-delete {
  margin-top: -5px;
  font-size: 22px;
}
.saved_address {
  width: 125%;
}

.ion-padding.custom_skeleton_div {
  display: flex;
}
.left_box {
  width: 71px;
  background: #eeeeee;
  margin-right: 1rem;
}
.skeleton {
  width: 100%;
}
ion-icon {
  pointer-events: none;
  cursor: pointer;
}
span.Ion_icon_custom ion-icon {
  width: 17px;
}

.Ion_icon_custom {
  cursor: pointer;

  margin-left: 2.1px;
}

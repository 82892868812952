.schedule_btn {
  margin: 10px auto 0px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 2px;
  font-size: 15px;
  text-transform: uppercase;
  height: 48px;
  border-radius: 10px;
  overflow: hidden;
  width: 93%;
}

* {
  font-family: "Montserrat", sans-serif;
}
.home_sliders{
  display: flex;
  margin: 0px;
}
.home_slider {
  padding-bottom: 10px;
}
.home_slider img{
  background: #ccc;
  min-height: 150px;
  min-width: 80vw;
  border: 1px solid #000000;
}

.block {
  overflow: auto;
  white-space: nowrap;
  margin: 10px 10px;
}
.block_one {
  background-color: #2e87da;
  height: 134px;
  display: inline-block;
  border-radius: 8px;
  margin-right: 15px;

  width: 65%;
}
.block_two {
  background-color: #c22eda;
  height: 134px;
  display: inline-block;
  border-radius: 8px;
  width: 65%;
}
.text {
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0 13px;
}
.d-flex {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 15px 20px;
  box-shadow: 0px 2px 7px rgba(169, 169, 169, 0.25);
  border-radius: 8px;
  color: block;
}
.date h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}
.date b {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}
.location {
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
  width: 83%;
}
.location p {
  margin-bottom: 0px;
  margin-top: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}
.location span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #3ab34a;
}
.mt-3 {
  margin-top: 1rem;
}
.impact_div {
  position: relative;
}
.impact_img {
  width: 100%;
  height: 237px;
}
.impact_img_text {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 1.9rem;
  left: 0;
  width: 100%;
}
.impact_img_text h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: capitalize;
  color: block;
  font-weight: bold;
}
.impact_img_text p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: block;
}
.refer_box {
  width: 105%;
  margin-left: -5%;
  margin-bottom: 2rem;
  position: fixed;
  bottom: -24px;
}
.refer_friend {
  display: flex;
  justify-content: space-around;
  background-color: #07243f;
  color: #fff;
  height: 56px;
  align-items: center;
}
.refer_friend img {
  width: 18px;
  height: 18px;
  align-self: center;
}
.referImage {
  align-items: center;
  align-self: center;
}
.refertext {
  text-decoration: none;
  color: white;
}
#text_white {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.local_login_form {
  position: static;
  background: #f6faf5;
  box-shadow: 2px 0px 15px #2c6b34;
  backdrop-filter: blur(80px);
  border-radius: 12px;
  padding: 1rem 2rem;
  width: 400px;
}
.local_login_title {
  margin-top: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: -1px;
  color: #5b5b5b;
  display: flex;
  align-items: center;
}
.back {
  font-size: 1.3rem;
  margin-right: .5rem;
  margin-top: 0.6rem;
  cursor: pointer !important;
  margin-left: -0.4rem;
  width: 2rem;
}
p.local_mobile_text {
  margin: 1rem 0rem 0.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #5b5b5b;
  text-transform: uppercase;
}
.sign_header_input {
  font-family: Inter;
  font-weight: 500;
}
.local_input_box {
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  display: flex;
  padding-left: 1rem;
  font-family: Inter;
  font-weight: 500;
}
.local_input_box span {
  width: 8%;
  font-weight: 500;
}
.local_input_box input#otp {
  width: 100%;
  outline: none;
  height: 100%;
  border-radius: 8px;
  padding: 0.7rem 0rem;
  outline: none;
  border: none;
  font-weight: 500;
  background: #f6faf5 !important;
  padding-left: .2rem;
}
.local_next_button {
  background: #3ab34a;
  box-shadow: 3px 4px 12px #257430;
  border-radius: 8px;
  margin-top: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  width: 150px;
  height: 50px;
  color: white;
  text-transform: capitalize;
  font-size: 17px;
}
.local_next_button:focus {
  outline: none;
  border: none;
}
.pickupBtn {
  width: 80%;
}
.receive_otp {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #5b5b5b;
  margin: 0.5rem 0px 0px;
}
.receive_otp span {
  color: #3ab34a;
  cursor: pointer;
}
.old_user_div {
  padding: 0rem 0rem;
}
button.local_next_button.old_user_button {
  width: 100%;
}
.lcd {
  font-family: Orbitron;
  font-weight: 600;
  font-size: 2rem !important;
}
.old_user_img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 3rem;
}
.old_user_img p.receive_otp {
  text-align: center;
}
.old_user_img img {
  width: 57%;
  margin: auto;
}
.first_login_kg {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-weight: 700;
  text-align: center;
  font-size: 1.2rem;
  text-align: center;
  width: calc(100% - 20px);
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}
.first_login_kg span,
.first_login_euro span {
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  font-size: .7rem;
}
.first_login_euro {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}
.local_login_form.first_login_form {
  width: 360px;
  padding: 1rem 1.4rem;
}
button.local_next_button.old_user_button {
  width: 83%;
}
.button_div {
  text-align: center;
}
@media (min-width: 768px) {
  .otpInput_main_div {
    justify-content: start;
  }
  input.input_get_otp {
    width: 3rem !important;
    height: 4rem;
    font-size: 1.5rem;
    margin: 20px 10px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
  }
}

.help_text {
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
}
.schedule_page.help_text {
  margin-left: 2rem;
}
.support_number {
  margin-top: .4rem;
}
.logout_facing_problems {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 1rem 0rem 0rem 0rem;
  justify-content: space-between;
}
.logout_facing_problems p {
  margin-bottom: 0px;
  font-size: 13px;
  font-style: italic;
  font-weight: 500;
}
.border-r {
  border: 1px solid red;
}
@media (max-width: 576px) {
  .local_login_form {
   
    width: 100%;
  }
}

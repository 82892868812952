.local_schdule_div {
  background: #f6faf5;
  background-image: url(https://ikp.edgekit.net/h1rxzpffx/scrapuncle/assets/Webpage_graphics_BLPHY130f.png?ik-sdk-version=javascript-1.4.3&updatedAt=1663274455661);
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: 50%;
  position: relative;
  overflow: auto;
  padding: 10px;
}
@media (max-width: 480px) {
  .local_schdule_div {
    background-image: none;
  }
}

.position_absolute_right_0 {
  position: absolute;
  right: 7px;
}
.schedule_pickup_div {
  /* height: 43rem; */
  overflow: auto;
}

.date_nd_time {
  /* display: flex; */
  margin-top: 0rem;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 16px !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  outline: 0;
  border: 0;
  width: 100%;
  padding: 10px;
}
.minimum_weigth_err_div {
  background: #fef3ce;
  color: black;
  padding: .5rem 1rem;
  border-radius: .3rem;
  position: relative;
  margin-bottom: 0.5rem;
  box-shadow: 4px 5px 8px #307c3036;
}
.estimate_title {
  margin-bottom: 0.5rem;
}

.minimum_weigth_err {
  letter-spacing: .2px;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: .8rem;
}
.alert_message_estimate_weight {
  line-height: 18px;
}
.alert_icon {
  font-size: 2rem;
}

.schedule_title {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -1.1px;
  line-height: 29px;
  color: #5b5b5b;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.remark_title {
  justify-content: flex-start;
}
.category_text {
  position: absolute;
  font-size: 0.7rem;
  text-transform: uppercase;
  background: #f6faf5;
  padding: 0rem 0.6rem;
  top: -0.6rem;
  left: 0.7rem;
  border-radius: 0.3rem;
}
.category_text.active {
  background: #3ab34a;
}
.title_text {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  height: 1rem;
}
.calendar_nd_date {
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
}
.calendar_nd_date ion-icon {
  margin-right: 0.3rem;
  font-size: 1.2rem;
  color: #5b5b5b;
}
.calendar_nd_date span {
  font-weight: 500;
  color: #7e7d7d;
  font-size: 0.9rem;
}
.location_tab {
  margin-top: 1rem;
}
.arrow_down_icon {
  margin-left: auto;
  color: #5b5b5b;
  font-size: .08rem;
}
.repeat_local {
  width: 186px;
}
.local_optional_text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "case" on;
  letter-spacing: 1px;
  color: #808080;
}
.upload_local {
  padding: 0.4rem;
  padding-left: 0rem;
}
.pickup_schedule_short_info {
  margin-top: 1rem;
  background-color: rgba(208, 23, 23, 0.073);
  border: 1px solid transparent;
  border-left: 8px solid red;
  color: rgb(177, 0, 0);
  padding: 1rem 1.2rem;
}
.pickup_schedule_short_info p {
  font-weight: 500;
  font-size: .8rem;
  margin: 0px;
}
.pickup_schedule_short_info p:first-child {
  margin-bottom: .5rem;
}
.pickup_schedule_short_info p:last-child a {
  font-weight: 600;
  color: #006d0e;
}

.remarks_local {
  width: 100%;
  padding: 0px;
}
.remarks_input {
  padding: 0.7em;
  background-color: #f6faf5;
  border: none;
  border-radius: 8px;
  width: 100%;
  color: #808080;
  font-weight: 500;
}
.remarks_input:focus {
  outline: none;
  border: none;
}

.schdule_button_nd_text {
  position: fixed;
  bottom: 0.6rem;
  right: 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.schedule_pickup_button_local {
  border-radius: 8px;
  width: 278px;
  height: 51px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  background: #3ab34a;
  box-shadow: 3px 4px 12px #133a18;
  border-radius: 8px;
  margin-top: 2rem;
}
button.schedule_pickup_button_local:disabled {
  background: #3ab34a;
  background: #c9c9c9;
  box-shadow: none;
  margin-bottom: 1rem;
}
.schedule_pickup_button_local:focus {
  outline: none;
  border: none;
}
.fill_schedule_text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #222;
}
.help_circle_icon {
  font-size: 2rem;
  color: #c9c9c9;
}
.time_details {
  display: flex;
  padding: 1rem 0rem 2rem;
  flex-wrap: wrap;
  justify-content: space-around;
}
.time_details span:hover {
  background: rgba(0, 238, 0, 0.137);
}
.time_details span.active_time:hover {
  background: #3ab34a;
}
.time_details span {
  width: 40%;
  margin-bottom: 0.5rem;
  background: darkred;
  text-align: center;
  height: 55px;
  background: #f6faf5;
  box-shadow: 2px 2px 4px rgb(44 107 52 / 25%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.3rem;
  cursor: pointer;
}
span.active_time {
  color: white;
  background: #3ab34a;
}
.react-datepicker,
.react-datepicker__month-container {
  width: 100% !important;
  border: none;
}
.react-calendar.schedule_calendar {
  background: none !important;
}

.react-calendar.schedule_calendar
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:disabled {
  background: none !important;
}
.react-calendar.schedule_calendar .react-calendar__navigation button[disabled] {
  background: none !important;
}
.react-calendar.schedule_calendar
  button.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  background: none !important;
}

ul.local_address_ul {
  list-style: none;
  padding: 0px 1rem;
}
.add_new_address_btn {
  cursor: pointer;
  background: #26ef411f;
  color: rgb(0, 162, 0) !important;
  border: none !important;
  padding: 1rem;
  text-align: center;
}
.add_new_address_btn:hover {
  box-shadow: 0 5px 5px rgb(228, 228, 228);
  border: 1px solid transparent;
  background: #3ab34a !important;
  color: white !important;
}
li.local_address_li {
  margin: 1.1rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
  padding-right: 1rem;
}
li.local_address_li:hover,
li.local_weight_li:hover {
  background: #008d000d;
}

.local_address_div {
  padding: 1rem;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  color: #808080;
  width: 100%;
  display: flex;
  gap: 5px;
  position: relative;
}
.active.local_address_li {
  background: #3ab34a !important;
}
.active.local_address_li div p,
.active.local_address_li div .close_icon {
  color: #fff;
}
.close_icon {
  font-size: 1.2rem;
}
p.address_line {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #5b5b5b;
  margin-bottom: 0px;
  margin-top: 0.4rem;
  padding-left: .3rem;
}

p.address_title {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  color: #808080;
  margin-bottom: 0px;
}
.local_repeat {
  display: flex;
  flex-direction: column;
}
.local_repeat span {
  padding: 0.2rem 1rem;
}
.local_repeat span:last-child {
  border-radius: 0px 0rem 0.5rem 0.5rem;
}
.local_repeat span:hover {
  background-color: rgba(230, 230, 230, 0.568);
}
.completed {
  color: #3ab34a !important;
}
select.repeat_pickup {
  border: none;
  background: #f6faf5;
  height: 100%;
  width: 68%;
}
.icon_nd_select ion-icon {
  padding: 0.5rem 0rem 0.5rem 0.6rem;
}
h2.ok_btn {
  background: #21a52f;
  border-radius: 20px;
  width: 50%;
  margin: 0rem auto 1rem;
  font-size: 1.1rem;
  cursor: pointer;
}
h2.ok_btn button {
  color: white;
}
h2.ok_btn:hover {
  background: #21a52f;
  box-shadow: 0px 0px 5px #21a52f;
}
.icon_nd_select select {
  padding: 0.5rem 1rem 0.5rem 0rem;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  color: #808080;
}
.icon_nd_select select option {
  font-weight: 500;
}
.icon_nd_select select:focus {
  outline: none;
  border: none;
}

.d-flexs {
  display: flex;
}
.cloud_upload_icon {
  border: 1px solid #807e7e;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 2rem;
  color: #c9c9c9;
  padding: 0.3rem;
  width: fit-content;
}
.cloud_Ion_icon {
  color: #5b5b5b;
}
.uploadIcon_uploadFile_name button {
  color: #5b5b5b;
  display: flex;
  align-items: center;
}
.icon_icon_name {
  display: flex;
  align-items: center;
}
.icon_icon_name p {
  margin-bottom: 0px;
  font-size: 12px;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  font-weight: 600;
}

.upload_more_photos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  height: auto;
  border: 1px solid #5b5b5b !important;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 2rem 0.5rem !important;
  cursor: pointer;
  margin-right: .7rem;
}
.upload_more_photos p {
  margin-bottom: 0.8rem;
  font-weight: 600;
  color: #808080;
}
.upload_more_photos ion-icon {
  color: #3ab34a;
  font-size: 2rem;
}
.upload_image {
  left: 0 !important;
  top: 0 !important;
  height: 90% !important;
}
.upload_image_block_box {
  left: 2;
}
.upload_image_block_box::after {
  display: none !important;
}
.closeIcon_upload {
  position: absolute;
  top: 0.8px;
  right: 0.2rem;
  width: 25px;
  height: 25px;
  color: black;
  background-color: white;
  border-radius: 50%;
}
.show_upload_image {
  display: flex;
}
.accordion-header {
  margin: 0px;
}
.photo_upload_div {
  margin-top: .5rem;
}
button.px-2.py-2.w-100.btn.btn-primary,
button.pr-2.pl-0.py-2.btn.btn-primary {
  background: none;
  border: none;
}
button.px-2.py-2.w-100.btn.btn-primary:hover,
button.pr-2.pl-0.py-2.btn.btn-primary:hover {
  background: none;
}
button.px-2.py-2.w-100.btn.btn-primary:focus,
button.pr-2.pl-0.py-2.btn.btn-primary:focus {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
button.px-2.py-2.w-100.btn.btn-primary:active,
button.pr-2.pl-0.py-2.btn.btn-primary:active {
  background: none !important;
  border: none !important;
  outline: none !important;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__tile.react-calendar__year-view__months__month,
button.react-calendar__tile.react-calendar__decade-view__years__year {
  color: black;
}
button.accordion-button,
button.accordion-button:active,
button.accordion-button:focus {
  background: none;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0.8rem .5rem;
}
.weight_select_div {
  position: relative;
}

.minimum_weigth_err_div {
  background: #d7f5d7;
  color: green;
  padding: .8rem .6rem;
  border-radius: .3rem;
  /* margin-top: .5rem; */
  position: absolute;
  top: -2rem;
  width: 24rem;
  right: 0rem;
}
.minimum_weigth_err_div::after {
  content: '';
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  transform: rotate(46deg);
  position: absolute;
  bottom: -6px;
  left: 2rem;
  background: #d7f5d7;
  box-shadow: 4px 5px 8px #307c3036;
}

.minimum_weigth_err {
  color: green;
}

.estimate_weight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: .5rem;
}
.estimate_weight select {
  cursor: pointer;
}
.trash_div {
  display: grid;
  padding: 0.4rem;
  border-radius: 50%;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  place-items: center;
}
.trash_icon {
  font-size: 1.2rem;
}
.trash_div:hover {
  box-shadow: 0 0 10px rgb(201, 201, 201);
  background-color: #018201;
}
.trash_div:hover .trash_icon {
  color: white;
}

.text-white {
  color: white;
}

.local_weight_li {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 5px;
  padding: 8px 10px;
  margin-bottom: 8px;
  cursor: pointer;
}
.local_weight_li.active {
  background-color: #3ab34a;
  color: white;
}
li.local_weight_li.active:hover,
li.local_address_li.active:hover {
  background-color: #3ab34a;
  color: white;
}

.back_icon_ {
  font-size: 2rem;
  width: fit-content;
  cursor: pointer;
}

.pb-2 {
  padding-bottom: 2rem !important;
}
.react-datepicker__day.react-datepicker__day--019.react-datepicker__day--today {
  font-weight: 400;
  background: transparent;
  color: black;
}
@media (max-width: 992px) {
  .schdule_button_nd_text {
    position: static !important;
    text-align: center !important;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}
@media (max-width: 992px) {
  .date_nd_time {
    margin-top: 2rem;
  }
}

ion-button.pickup_btn.ion-color.md.button.button-solid.ion-activatable.ion-focusable.hydrated {
  width: 46%;
  height: 47px;
}
.pickups_summery_details {
  background: white;
  height: 99%;

  margin-top: 1rem;

  padding: 1rem 2rem 1.5rem 2rem;
}
.hidden {
  display: none!important;
}
.pickup_upcoming {
  height: 88vh;
  margin-top: 1rem;
  margin-left: 2rem;
  overflow-y: auto;
  padding-right: 0.5rem;
  background-color: white;
  padding-bottom: 3rem;
}

.date_title {
  display: flex;

  align-items: center;
}
.date {
  text-align: center;
  width: 50px;
}
.w-10 {
  width: 10%;
}
.date p {
  margin-bottom: 0rem;
}
.title h2 {
  font-weight: bold;
  margin: 0px;
  margin-left: 1rem;
}
.status {
  width: 60%;
}
.status_recyclingHero {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.status_name {
  margin-top: 0.7rem;
  /* Subtext */

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
  width: 40%;
}
.status_value {
  margin-top: 0.7rem;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-transform: capitalize;

  color: #000000;
}
.no_document {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #6d6d6d;
}
.recyclingHero {
  text-align: center;
}
.recyclingHero h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}
.profile_picture {
  height: 80px;
  margin: auto;
}
.recylables_p {
  /* Subtext */

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #000000;
}
.cardScrap {
  width: 88px;
  height: 109px;
}

ion-button.btn_pickup_summerry.ion-color.md.button.button-solid.ion-activatable.ion-focusable.hydrated {
  width: 40%;
  height: 38px;
}
.recyclables {
  margin-top: 1rem;
}
.text-icon {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background: white;
  width: 100%;
  border-radius: 10px;
  margin-top: 1rem;
}
.calender_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calender_text p {
  margin-bottom: 0px;
}
.calender_text img {
  margin-right: 1rem;
}
.text-icon .images {
  width: 15px;
  height: 10px;
}
.upcoming_sub_title {
  /* Subheading_TitleCase */
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  text-transform: capitalize;
  color: #000000;
  margin-top: 1.5rem;
}
.allupLoad {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin-left: 1rem;
  color: #6d6d6d;
}
.img_plus {
  display: flex;
  align-items: center;
}
svg.MuiSvgIcon-root.plus_icon {
  font-size: 33px;
  color: white;
  background: green;
  border-radius: 32px;
  width: 30px;
  height: 30px;
  margin-left: 1rem;
}
.web_view_box {
  width: 100%;
}
.web_view_box .date b {
  font-size: 29px;
}
.web_view_box .date h3 {
  font-size: 18px;
}
.web_view_box .location p {
  font-size: 13px;
}
.web_view_box .location span {
  font-size: 11.7px;
  font-weight: 500;
}
.date_title .date p {
  font-size: 30px;
  margin-top: -10px;
}
.web_view_impact_content.web_view_top {
  padding-top: 0px;
}
.upcming.hook {
  display: flex;
  justify-content: space-between;
}
.margin_top_div {
  margin-top: 0.5rem;
}
h4.pickup_agent_mobile_number {
  margin-top: -0.4rem;
}
.pickup_block_box::after {
  display: none;
}
.pickup_block_box img {
  height: 65px;
}
.upcoming_pickup_content {
  height: 94vh;
}
.commplete_pickup_content {
  height: 100%;
}

.sign_header_h3 {
  margin-top: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.sign_header_paragraph {
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 2rem 0px 0px;
}
.sign_header_input {
  height: 43px;
  border: none;
  background: #F6FAF5;
  border-radius: 8px;
  width: 100%;
  font-weight: 500;
  padding-left: 0.6rem;
  color: black;
}
.sign_header_input.details {
  margin-top: 0.5rem;
}
.sign_header_input:focus {
  outline: none;
  border: none;
}
.sign_resend_code {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3ab34a;
  margin: 1rem 0px 0px;
  width: fit-content;
}
.household_box {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.household {
  border: 2px solid #a4a4a4;
  box-sizing: border-box;
  border-radius: 8px;
  width: 70px;
  height: 80px;
}
.household_para {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #a4a4a4;
  margin-top: 10px;
}
.household_para.actives {
  color: #3ab34a;
}
.bussiness_header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 2rem 0px 0px;
}
.check_terms_box {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.terms_condition {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #5b5b5b;
  margin-bottom: 0px;
  margin-left: 0.5rem;
}
.sign_header_paragraph_input {
  margin-left: 2px;
  width: 80%;
  border: none;
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  height: 100%;
}
.sign_header_paragraph_input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
#bussiness_nature {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-left: 0px;
  border: 2px solid #3ab34a;
  background-color: white;
}
svg.MuiSvgIcon-root.arrow_key {
  margin-right: 0.5rem;
  font-size: 1.9rem;
  margin-top: 0.6rem;
  color: white;
  border-radius: 50%;
  width: 19px;
  background: #53bd60;
  height: 19px;
}
img.household.select {
  border-color: #3ab34a !important;
}
ion-button.bottom_Btn.ion-color.ion-color-success.md.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated {
  height: 56px;
  border: none;
}
ion-grid.ionGrid.ios.hydrated {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.m-0 {
  margin: 0px !important;
}
.error_para {
  height: 10px;
  font-size: 12px;
  margin-top: 8px;
  color: red;
  font-weight: 500;
}
.not_now {
  color: red;
  margin-left: 10px;
  font-size: 12px;
}
.sign_resend_code.invalid{
  color: #fff;
  background: #c30c0c;
  font-weight: 500;
  /* margin-top: 5px; */
  cursor: pointer;
  padding: 7px;
  font-size: 10px;
  margin: 7px auto;
  border-radius: 8px;
}
.sign_resend_code.resend {
  color: #c30c0c;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
  font-size: 11px;
}
.sign_resend_code.not_now {
  cursor: pointer;
}
input#otp {
  background: #efefef !important;
  margin: 0px !important;
}

input.input_get_otp {
  width: 3rem !important;
  height: 4rem;
  font-size: 1.5rem;
  margin: 20px 10px 10px;
  border: 1px solid black;
  border-radius: 2px;
  background-color: white;
}

.otpInput_main_div {
  justify-content: center;
}
.check_inbox {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 1rem;
}
#bottom_btn_bussiness_btn {
  bottom: -70px;
}
.category_box {
  display: flex;
  justify-content: space-around;
}
.sign_up_middle_child {
  margin-top: 17px !important;
}
.selected_icon {
  border: 2px solid green;
  border-radius: 5px;
  height: 80px;
  width: 70px;
  margin: auto;
}
.category_box_box {
  text-align: center;
  width: 33%;
  margin-top: 0.5rem;
}
.non_selected_icon {
  border: 2px solid gray;
  border-radius: 5px;
  height: 80px;
  width: 70px;
  margin: auto;
}
.selected_text {
  color: green;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.non_selected_text {
  color: gray;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
